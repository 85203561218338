.app {
    min-height: 100vh;
}

.sider {
    background-color: #f0f2f5;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
}

.sider__logo {
    height: 32px;
    background-image: url('../imgs/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 16px;
}

.header {
    background: #ffffff;
    padding: 0;
    position: fixed;
    z-index: 1;
    width: 100%;
}

.header__trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
}

.header__trigger:hover {
    color: #00aeef;
}

.content {
    margin-top: 65px;
    overflow: initial;
    padding: 24px;
    background: #ffffff;
}

.footer {
    text-align: center;
}


.ant-form-item {
    margin-bottom: 0!important;
}